<template>
  <div class="invoice">
    <div class="inv-inner">
      <!-- Invoice Header -->
      <div class="inv-header">
        <Icon class="h-6 w-auto" name="logo" />
        <div class="inv-number">
          <span>Invoice Number</span>
          <p>MD179846</p>
        </div>
      </div>
      <div class="inv-icon">
        <span><Icon name="package-filled"/></span>
      </div>

      <!-- Invoice Content -->
      <div class="inv-details">
        <div class="address">
          <div>
            <span class="small">Ship From</span>
            <p>Brooklyn Richards</p>
            <span>
              9 Woodland Rd. Suite D<br />
              Roseland, NJ 07068
            </span>
          </div>
          <div>
            <span class="small">Ship To</span>
            <p>Brooklyn Richards</p>
            <span>
              9 Woodland Rd. Suite D<br />
              Roseland, NJ 07068
            </span>
          </div>
        </div>
        <div class="details">
          <div class="pay-status">
            <span class="paid">Paid</span>
            <span class="unpaid">Unpaid</span>
            <span class="partial">Partial</span>
          </div>
          <div class="detail-item">
            <span>Order Number</span>
            <p>MD179846</p>
          </div>
          <div class="detail-item">
            <span>Issue Date</span>
            <p>Apr 12 22</p>
          </div>
          <div class="detail-item">
            <span>Payment Due</span>
            <p>Apr 12 22</p>
          </div>
        </div>
      </div>

      <!-- List Items -->
      <div class="inv-list">
        <div class="inv-list-header">
          <div class="name">Item</div>
          <div class="price">Price</div>
          <div class="qty">Qty</div>
          <div class="tax">Tax</div>
          <div class="total">Total</div>
        </div>
        <div class="inv-list-items">
          <div class="inv-list-item" v-for="l in list" :key="l.name">
            <div class="name flex items-center space-x-4">
              <img :src="l.image" :alt="l.name" class="w-7 h-7" />
              <div
                class="flex flex-col space-y-1.5 leading-none text-xs font-medium"
              >
                <p class="text-theme-secondary">{{ l.name }}</p>
                <span class="text-theme-body">{{ l.brand }}</span>
              </div>
            </div>
            <div class="price">{{ l.price }}</div>
            <div class="qty">{{ l.qty }}</div>
            <div class="tax">{{ l.tax }}</div>
            <div class="total">{{ l.total }}</div>
          </div>
        </div>
        <div class="inv-pricing">
          <div class="tax-pricings">
            <div class="flex items-center space-x-2 mb-3">
              <Icon class="w-5 h-5 -mt-0.5" name="document-filled" />
              <span class="text-md font-medium">Tax Summary</span>
            </div>
            <div class="price-item">
              <p>TAX RULE NAME</p>
              <span>$9,998.00</span>
            </div>
            <div class="price-item">
              <p>TAX RULE NAME</p>
              <span>$478.00</span>
            </div>
            <div class="price-item">
              <p>TAX RULE NAME</p>
              <span>$1478.00</span>
            </div>
            <div class="price-item highlight">
              <p>Total Taxes</p>
              <span>$9,998.00</span>
            </div>
          </div>
          <div class="order-pricings">
            <div class="price-item">
              <p>Order Total</p>
              <span>$9,998.00</span>
            </div>
            <div class="price-item">
              <p>Taxes</p>
              <span>$478.00</span>
            </div>
            <div class="price-item">
              <p>Shipping</p>
              <span>$1478.00</span>
            </div>
            <div class="price-item highlight">
              <p>Sub Total</p>
              <span>$9,998.00</span>
            </div>
            <div class="price-item extra">
              <p>Medusa Coins</p>
              <span>-$478.00</span>
            </div>
            <div class="price-item extra">
              <p>Store Credit</p>
              <span>-$478.00</span>
            </div>
            <div class="price-item extra">
              <p>Coupon Code</p>
              <span>-$478.00</span>
            </div>
            <div class="price-item highlight">
              <p>Total</p>
              <span>$9,998.00</span>
            </div>
            <div class="price-item highlight no-border ">
              <p>Paid</p>
              <span>$9,998.00</span>
            </div>
            <div class="price-item highlight no-border ">
              <p>Balance</p>
              <span>$0.00</span>
            </div>
          </div>
        </div>
      </div>

      <div class="px-10 pt-14 pb-5 text-center space-y-3">
        <p class="text-theme-secondary font-light text-base">
          MEDUSA STATE LICENSE TEXT STRING
        </p>
        <p class=" text-theme-dark-shade font-medium text-sm">
          We appreciate your business.
        </p>
      </div>

      <!-- Invoice Footer -->
      <div class="inv-footer">
        <span
          >Medusa Distribution LLC 9 Woodland Rd., Suite D,<br />
          Roseland, NJ, 07068, US
        </span>
        <div class="info">
          <span>Email sales@medusadistribution.com</span>
          <span>Phone 973-808-1101</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const list = [
  {
    name: "Black Box",
    brand: "Cartisan",
    image: "/images/1.png",
    price: "$25.00",
    qty: 2,
    tax: "0.00",
    total: "$240.97",
  },
  {
    name: "Monster Batteries",
    brand: "Jam Monster",
    image: "/images/2.png",
    price: "$25.00",
    qty: 2,
    tax: "0.00",
    total: "$240.97",
  },
  {
    name: "Novo 3",
    brand: "Smok",
    image: "/images/3.png",
    price: "$25.00",
    qty: 2,
    tax: "0.00",
    total: "$240.97",
  },
];
export default {
  name: "Invoice",
  setup() {
    return {
      list,
    };
  },
};
</script>

<style lang="scss" scoped>
.invoice {
  @apply w-full h-full p-8;

  .inv-inner {
    @apply max-w-5xl mx-auto bg-white;
  }

  .inv-header {
    @apply w-full px-10 py-8 flex items-center justify-between;
    background: #f8f8f8;

    .inv-number {
      @apply flex items-center text-sm font-medium space-x-3;
      color: rgba(72, 90, 93, 0.54);

      p {
        @apply text-base;
        color: #485a5d;
      }
    }
  }

  .inv-icon {
    @apply w-full flex items-center justify-center pb-10;

    span {
      @apply flex items-center justify-center w-14 h-14 border  rounded-full flex-shrink-0 bg-white -mt-7;
      border-color: #dbdbdb;

      svg {
        @apply w-9 h-9;
      }
    }
  }

  .inv-details {
    @apply flex items-start justify-between text-sm text-theme-body px-10 pb-8 leading-normal;

    .small {
      @apply inline-block text-xs text-theme-body uppercase font-normal mb-2.5;
    }

    .address {
      @apply flex flex-col space-y-5;

      > div {
        @apply w-full;
      }
      p {
        @apply text-theme-secondary mb-1;
      }
    }

    .details {
      .detail-item {
        @apply flex items-center py-1;

        span {
          @apply w-40;
        }

        p {
          @apply text-theme-secondary text-left;
        }
      }
    }
  }

  .inv-list {
    .inv-list-header {
      @apply flex items-center w-full px-10 py-4 border-b text-theme-body font-normal uppercase text-sm;
      border-color: rgba(180, 180, 180, 0.25);
    }
    .name {
      @apply flex-grow;
    }

    .price,
    .qty,
    .tax {
      @apply w-24;
    }

    .total {
      @apply w-24;
    }

    .inv-list-items {
      @apply w-full px-10 divide-y divide-gray-100;

      .inv-list-item {
        @apply flex items-center py-3 text-md text-theme-body font-light;
        .total {
          @apply font-medium text-theme-dark;
        }
      }
    }
  }

  .inv-pricing {
    @apply grid grid-cols-2 py-5 px-10 mb-12 border-t gap-32;
    border-color: rgba(180, 180, 180, 0.25);

    .order-pricings {
      @apply flex flex-col items-end;
    }

    .taxt-pricings {
      @apply flex flex-col items-start;
    }

    .price-item {
      @apply inline-flex items-center justify-between flex-shrink-0 w-full text-md text-theme-dark-shade font-light space-y-1.5;

      p {
        @apply w-3/5;
      }

      span {
        @apply w-2/5 text-right;
      }

      &.highlight {
        @apply text-theme-secondary font-medium py-2 border-t mt-2;
        border-color: rgba(180, 180, 180, 0.25);

        &.no-border {
          @apply border-none py-0 mt-0;
        }
      }

      &.extra {
        @apply font-medium text-sm;
        color: #8fa3a9;
      }
    }
  }

  .inv-footer {
    @apply w-full px-10 py-6 border-t  flex items-start justify-between text-sm font-light text-theme-body leading-normal;
    border-color: rgba(180, 180, 180, 0.25);

    span {
      @apply block;
    }
  }
  .pay-status {
    @apply flex flex-col items-end mb-8 space-y-3;

    span {
      @apply uppercase font-normal text-black inline-block px-3 py-1.5 rounded border  leading-none text-xs w-24 text-center;

      &.paid {
        @apply border-green-500 bg-green-50;
      }

      &.unpaid {
        @apply border-red-500 bg-red-50;
      }
      &.partial {
        @apply border-yellow-500 bg-yellow-50;
      }
    }
  }
}
</style>
